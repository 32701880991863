module.exports = {
  defaultTitle: 'Francois Mariette',
  logo: 'https://portfolio.smakosh.com/favicon/favicon-512.png',
  author: 'Francois Mariette',
  url: 'https://www.francoismariette.com',
  legalName: 'Francois Mariette',
  defaultDescription: 'I’m Francois and I’m a Full-Stack Engineer!',
  socialLinks: {
    twitter: 'http://www.twitter.com/smakosh',
    github: 'https://github.com/frankmariette',
    linkedin: 'https://www.linkedin.com/in/ismail-ghallou-630149122/',
    instagram: 'https://instagram.com/smakosh19',
    youtube: 'https://www.youtube.com/user/smakoshthegamer',
    google: 'https://plus.google.com/u/0/+IsmailSmakoshGhallou',
  },
  googleAnalyticsID: 'UA-88875900-4',
  themeColor: '#6b63ff',
  backgroundColor: '#6b63ff',
  social: {
    facebook: '',
    twitter: '',
  },
  address: {
    city: 'San Diego',
    region: 'CA',
    country: 'USA',
  },
  contact: {
    email: 'email',
    phone: 'phone number',
  },
  foundingDate: '2018',
  recaptcha_key: '6Lcs6lQUAAAAAEwhNH2IsobIe2csdda4TU3efpMN',
};
